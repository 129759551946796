<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { Fold } from '@element-plus/icons-vue'
import { computed } from 'vue'
import { useSupportRegister } from '@/composables'

const $route = useRoute()
const supportRegister = useSupportRegister()

const links = computed(() => {
  const { path } = $route
  return [
    {
      href: '/',
      text: '首页',
    },
    {
      href: '/login',
      text: '登录',
    },
    {
      href: '/register',
      text: '快速注册',
    },
  ]
    .filter(nav => nav.href !== path)
    .filter(nav => supportRegister.value || nav.href !== '/register')
})
</script>

<template>
  <div>
    <nav class="navbar">
      <div class="right-menu">
        <label for="nav-open">
          <span class="menu-icon">
            <el-icon :size="28">
              <Fold />
            </el-icon>
          </span>
        </label>
      </div>
      <input id="nav-open" type="checkbox">
      <div id="miniMenu">
        <ul class="nav-list" />
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
#nav-open {
  position: absolute;
  z-index: -1;
}
.navbar {
  color: #fff;
  padding: 12px 10px 10px 10px;

  .right-menu {
    margin-right: 15px;
    display: none;
    .menu-icon {
      font-size: 28px;
    }
  }
  .nav-list {
    display: flex;
    justify-content: left;
    li {
      text-align: center;
      list-style: none;
      color: #fff;
      margin: 0 20px;
    }
    a {
      color: #fff;
      opacity: 0.8;
      &:hover {
        opacity: 1;
        text-shadow: 0 0 3px #eee;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    .right-menu {
      display: block;
      float: right;
      font-size: 20px;
    }
    #nav-open:checked + #miniMenu {
      .nav-list {
        display: flex;
      }
    }
    .nav-list {
      display: none;
      flex-direction: column;
      li {
        margin-top: 6px;
      }
    }
  }
  #miniMenu {
    position: absolute;
    right: 10px;
    top: 40px;
  }
}
</style>
